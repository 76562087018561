import React from "react"
import PropTypes from "prop-types"
import * as styles from "./__styles__/form-field.module.scss"

// SVGs
import Error from "./../../images/svgs/error.svg"

const FormField = ({
  type,
  name,
  id,
  value,
  validated,
  error,
  handleInput,
  label,
  placeholder,
  required,
}) => {
  return (
    <>
      <div
        className={`${styles.field} ${validated ? "" : styles.errorWrapper}`}
      >
        <label htmlFor={name}>{label}</label>
        <div>
          {!validated && <Error />}
          {type === "textarea" && (
            <textarea
              className={styles.textarea}
              name={name}
              id={id}
              value={value}
              placeholder={placeholder}
              aria-invalid={!validated ? "true" : "false"}
              aria-required={required}
              onChange={e => {
                handleInput(e)
              }}
            />
          )}
          {type !== "textarea" && (
            <input
              type={type}
              name={name}
              id={name}
              value={value}
              placeholder={placeholder}
              aria-invalid={!validated ? "true" : "false"}
              aria-required={required}
              onChange={e => {
                handleInput(e)
              }}
            />
          )}
        </div>
        {!validated && <p className={styles.error}>{error}</p>}
      </div>
    </>
  )
}

export default FormField

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  validated: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleInput: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.string.isRequired,
}

FormField.defaultProps = {
  value: "",
  error: "",
  placeholder: "",
}
