import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import * as styles from "./__styles__/contact-form.module.scss"
import { load } from "recaptcha-v3"

// Components
import FormField from "./../atoms/form-field"

// SVGs
import Spinner from "./../../images/svgs/spinner.svg"

const ContactForm = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          thankyou: wpPage(databaseId: { eq: 190 }) {
            uri
          }
          error: wpPage(databaseId: { eq: 194 }) {
            uri
          }
        }
      `}
      render={data => <ContactFormComponent data={data} />}
    />
  )
}

class ContactFormComponent extends React.Component {
  static validateRequired(value) {
    if (value.length > 0) {
      return {
        validated: true,
        error: "",
      }
    }
    return {
      validated: false,
      error: "This field is required",
    }
  }

  static validateEmailInput(value, required) {
    const regex = /^(.{1,})@(.{1,})$/

    if ((!required && value.length === 0) || regex.test(value)) {
      return {
        validated: true,
        error: "",
      }
    }

    if (value.length > 0 && !regex.test(value)) {
      return {
        validated: false,
        error: "Please enter a valid email address",
      }
    }

    return {
      validated: false,
      error: "This field is required",
    }
  }

  static loadRecaptcha() {
    if (typeof document !== "undefined") {
      const badge = document.querySelector(".grecaptcha-badge")
      if (badge) {
        badge.style.display = "block"
      } else {
        load("6LcS2e0aAAAAAK7TQNYLKccyYzrSfDYtOjscrBuH", {
          explicitRenderParameters: {
            badge: "bottomright",
          },
        })
      }
    }
  }

  static unLoadRecaptcha() {
    if (typeof document !== "undefined") {
      const badge = document.querySelector(".grecaptcha-badge")
      if (badge) {
        // console.log(badge)
        badge.style.display = "none"
      }
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      name: { value: "", validated: true, error: "" },
      surname: { value: "", validated: true, error: "" },
      email: { value: "", validated: true, error: "" },
      organisation: { value: "", validated: true, error: "" },
      message: { value: "", validated: true, error: "" },
      submitting: false,
      validated: false,
    }

    // Binds
    this.handleInput = this.handleInput.bind(this)

    // Refs
    this.recaptchaBadge = React.createRef()
  }

  componentDidMount() {
    ContactFormComponent.loadRecaptcha()
  }

  componentWillUnmount() {
    ContactFormComponent.unLoadRecaptcha()
  }

  handleInput(event) {
    const name = event.target.getAttribute("name")
    this.setState({
      [name]: {
        value: event.target.value,
        validated: true,
      },
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({
      submitting: true,
    })
    this.validate()
  }

  validate() {
    const { name, surname, email, message, organisation } = this.state
    const nameValidated = ContactFormComponent.validateRequired(name.value)
    const surnameValidated = ContactFormComponent.validateRequired(
      surname.value
    )
    const messageValidated = ContactFormComponent.validateRequired(
      message.value
    )
    const emailValidated = ContactFormComponent.validateEmailInput(
      email.value,
      true
    )
    this.setState(
      {
        name: {
          value: name.value,
          validated: nameValidated.validated,
          error: nameValidated.error,
        },
        surname: {
          value: surname.value,
          validated: surnameValidated.validated,
          error: surnameValidated.error,
        },
        email: {
          value: email.value,
          validated: emailValidated.validated,
          error: emailValidated.error,
        },
        organisation: {
          value: organisation.value,
          validated: true,
          error: "",
        },
        message: {
          value: message.value,
          validated: messageValidated.validated,
          error: messageValidated.error,
        },
        validated:
          nameValidated.validated &&
          surnameValidated.validated &&
          messageValidated.validated &&
          emailValidated.validated,
      },
      () => {
        this.reCaptcha()
      }
    )
  }

  reCaptcha() {
    const { validated } = this.state

    if (validated) {
      load("6LcS2e0aAAAAAK7TQNYLKccyYzrSfDYtOjscrBuH").then(recaptcha => {
        recaptcha.execute("submit").then(token => {
           console.log(token) // Will print the token
          this.send(token)
        })
      })
    } else {
      this.setState({
        submitting: false,
      })
    }
  }

  async send(token) {
    const { name, surname, email, message, organisation } = this.state
    const { data } = this.props
    const params = {
      name: name.value,
      surname: surname.value,
      email: email.value,
      organisation: organisation.value,
      message: message.value,
      token,
    }
    const body = new URLSearchParams(params).toString()
    const response = await fetch(process.env.GATSBY_WPRESTAPI_SEND_EMAIL_URL, {
      method: "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body,
    })
    const res = await response.json()
    // console.log(res)
    if (res.status >= 200 && res.status <= 299) {
      navigate(data.thankyou.uri)
    } else {
      navigate(data.error.uri, { state: { res } })
    }
  }

  render() {
    const {
      name,
      surname,
      email,
      message,
      organisation,
      submitting,
    } = this.state
    return (
      <section>
        <form
          className={styles.form}
          method="post"
          onSubmit={e => {
            this.handleSubmit(e)
          }}
          autoComplete="off"
        >
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldCol}>
              <FormField
                type="text"
                name="name"
                id="name"
                value={name.value}
                validated={name.validated}
                error={name.error}
                handleInput={this.handleInput}
                required="true"
                label="First name*"
              />
            </div>
            <div className={styles.fieldCol}>
              <FormField
                type="text"
                name="surname"
                id="surname"
                value={surname.value}
                validated={surname.validated}
                error={surname.error}
                handleInput={this.handleInput}
                required="true"
                label="Last name*"
              />
            </div>
          </div>
          <div className={styles.fieldWrapper}>
            <div className={styles.fieldCol}>
              <FormField
                type="text"
                name="email"
                id="email"
                value={email.value}
                validated={email.validated}
                error={email.error}
                handleInput={this.handleInput}
                required="true"
                label="Email address*"
              />
            </div>
            <div className={styles.fieldCol}>
              <FormField
                type="text"
                name="organisation"
                id="organisation"
                value={organisation.value}
                validated={organisation.validated}
                error={organisation.error}
                handleInput={this.handleInput}
                required="false"
                label="Organisation"
              />
            </div>
          </div>
          <FormField
            type="textarea"
            name="message"
            id="message"
            value={message.value}
            validated={message.validated}
            error={message.error}
            handleInput={this.handleInput}
            label="How can we help?*"
            required="true"
            placeholder="Hi there, I would love to receive a survey document&hellip;"
          />
          <div className={`${styles.submitField}`}>
            <button
              className={styles.button}
              id="send"
              type="submit"
              disabled={submitting}
              style={{ backgroundColor: "#005eb8" }}
            >
              Send message
            </button>
            {submitting && (
              <div className={styles.spinner}>
                <Spinner />
              </div>
            )}
          </div>
        </form>
      </section>
    )
  }
}

export default ContactForm

ContactFormComponent.propTypes = {
  data: PropTypes.shape({
    thankyou: PropTypes.shape({
      uri: PropTypes.string,
    }),
    error: PropTypes.shape({
      uri: PropTypes.string,
    }),
  }),
}
